import { Link } from 'gatsby';
import * as React from 'react';
import { NewAppTitle, NewAppText, NewAppButton } from '../../components/ui';
// import { LostPageLogo, LostPageError } from '../assets';
import { mobileSize } from '../../utils';
import styled from 'styled-components';
import { IndexLayout } from '../../layouts/';
import Helmet from 'react-helmet';
import { NavBar } from '../../components/segments/NavBar';
import DarkFooter from '../../components/segments/DarkFooter';
import { theme } from '../../utils/theme';
import { desktopSize, laptopSize } from '../../utils/variables';
import LetsConnectSection from '../../components/segments/LetsConnectSection';
import ClientBox from '../../components/ClientBox';
import realtorLogo from '../../images/work/logos/Realtor.png';
import realtorImg from '../../images/work/client-image/Realtor.png';
import imprintLogo from '../../images/work/logos/Imprint.png';
import imprintImg from '../../images/work/client-image/Imprint.png';
import imprintBg from '../../images/work/backgrounds/Imprint.png';
import tappingLogo from '../../images/work/logos/TS.png';
import tappingImg from '../../images/work/client-image/Tapping Solution.png';

import opsiqLogo from '../../images/work/logos/OpsIQ.png';
import opsiqImg from '../../images/work/client-image/OpsIQ.png';
import opsiqBg from '../../images/work/backgrounds/OpsIQ.png';

import movoLogo from '../../images/work/logos/Movo.png';
import movoImg from '../../images/work/client-image/Movo.png';
import movoBg from '../../images/work/backgrounds/Movo.png';

import conairLogo from '../../images/work/logos/Conair.png';
import conairImg from '../../images/work/client-image/Conair.png';

import rentalsLogo from '../../images/work/logos/Rentals.png';
import rentalsImg from '../../images/work/client-image/Rentals.png';
import rentalsBg from '../../images/work/backgrounds/Rentals.png';

import graceLogo from '../../images/work/logos/Grace.png';
import graceImg from '../../images/work/client-image/Grace.png';
import graceBg from '../../images/work/backgrounds/Grace.png';

import promgirlLogo from '../../images/work/logos/Promgirl.png';
import promgirlImg from '../../images/work/client-image/Promgirl.png';

import brownLogo from '../../images/work/logos/Brown University.png';
import brownImg from '../../images/work/client-image/Brown University.png';

import base10Logo from '../../images/work/logos/Base10.png';
import base10Img from '../../images/work/client-image/Base10.png';

export default () => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Rapptr Labs | Work</title>
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <PageContainer>
        <TextContainer>
          <PageMainText>Bringing Digital Products to Life</PageMainText>
          <PageDescriptionText>
            From ideation to development, the Rapptr team has helped dozens of clients build and
            launch custom mobile and web apps and premium digital experiences. Our clients include
            companies of all sizes, from high-growth startups to enterprise brands and everything in
            between.
          </PageDescriptionText>
        </TextContainer>
      </PageContainer>
      <ClientsGrid>
        <ClientBox
          summary="Home Buying Made Easy"
          description="Whether you're renting, buying, or looking for a home's estimated value, Realtor.com makes home search fun and easy with a robust set of tools like video walkthroughs and virtual tours. Realtor's intuitive experience lends a guiding hand throughout the process to help you browse millions of listings to find the perfect home for you."
          clientImg={realtorImg}
          clientLogo={realtorLogo}
          clientLogoHeight={25}
          // clientBg={realtorBg}
          bgColor="#D6262D"
          name="realtor"
          // cardHeight={457}
          caseStudy="realtor"
        />
        <ClientBox
          summary="Learn Visually. Read Faster. Remember More."
          description="A fully immersive experience, Imprint uses beautiful illustrations and animations to help users understand complex concepts quickly. Quizzes and visualized insights from bestselling nonfiction books and other original content. Empowers users to learn essential topics and skills for work and life."
          clientImg={imprintImg}
          clientLogo={imprintLogo}
          clientBg={imprintBg}
          bgColor="linear-gradient(172.42deg, #79C9CD 6.32%, #75C7CC 52.82%, #6AC3C8 95.75%)"
          name="imprint"
          // cardHeight={457}
          caseStudy="imprint"
        />
        <ClientBox
          summary="Improving Call Efficiency and Performance"
          description="OpsIQ is an industry-leading Voice over IP (VoIP) web-based enterprise application focused on delivering technology-driven solutions that help global users reduce costs and improve overall performance and customer satisfaction."
          clientImg={opsiqImg}
          clientLogo={opsiqLogo}
          clientBg={opsiqBg}
          textColor={theme.MAIN_DARK_BLUE}
          // bgColor="linear-gradient(172.42deg, #79C9CD 6.32%, #75C7CC 52.82%, #6AC3C8 95.75%)"
          name="opsiq"
          // cardHeight={457}
          noMask
        />
        <ClientBox
          summary="Relax, Stress Less, Sleep Well"
          description="Utilizing Emotional Freedom Techniques (EFT’s), the Tapping Solution is an alternative acupressure therapy treatment that aims to help users lower stress, reduce anxiety, get better sleep, and so much more."
          clientImg={tappingImg}
          clientLogo={tappingLogo}
          bgColor="#DFE1F5"
          name="tapping"
          // cardHeight={457}
          textColor="#053148"
          caseStudy="tapping-solution"
          noMask
        />
        <ClientBox
          summary="Kicks and Scooter Sharing Made Simple"
          description="Bringing mobility and adventure to users in South America, the MOVO app makes it possible to locate and unlock a scooter or motorbike through a simple QR code so that users can move around the city at their own pace."
          clientImg={movoImg}
          clientLogo={movoLogo}
          clientLogoHeight={33}
          clientBg={movoBg}
          // bgColor="#DFE1F5"
          name="movo"
        />
        <ClientBox
          summary="Track Weight, Body Water, and Your Progress"
          description="The WW smart bathroom scale app is a companion app to Conair's smart bathroom scales. Easily track your weight, body fat, body water, bone mass, and BMI (Body Mass Index). Users can automatically sync their weight to the WW® app and track everything at a glance on the dashboard or go in-depth with graphs and charts."
          clientImg={conairImg}
          clientLogo={conairLogo}
          clientLogoHeight={31}
          bgColor="linear-gradient(270deg, #15B9E0 0%, #6BCBE9 100%)"
          name="conair"
        />
        <ClientBox
          summary="Renting Made Easy"
          description="Rentals.ca makes finding a new rental home simple and fast. Users save time and eliminate the stress of searching through thousands of apartments, houses, and condos for rent across Canada using the apps’ easy-to-use map-based search combined with high-performing filters and listing alerts."
          clientImg={rentalsImg}
          clientLogo={rentalsLogo}
          clientLogoHeight={34}
          clientBg={rentalsBg}
          name="rentals.ca"
        />
        <ClientBox
          summary="Sleep, Relax and Feel Better"
          description="The Grace hypnosis and meditation app helps users improve their mental state in as little as 12 minutes. Through the power of self-guided relaxing audio sessions, app users benefit from hypnotherapy practices that help them focus on making positive changes in their lives, that stick."
          clientImg={graceImg}
          clientLogo={graceLogo}
          clientLogoHeight={52}
          clientBg={graceBg}
          name="grace"
        />
        <ClientBox
          summary="The Ultimate Prom Shopping Experience"
          description="PromGirl's app helps shoppers easily browse their entire collection of dresses, shoes, and accessories for every special occasion or special event. Guests can create shareable profiles that help them stay on trend and budget."
          clientImg={promgirlImg}
          clientLogo={promgirlLogo}
          bgColor="#FF218A"
          name="promgirl"
        />
        <ClientBox
          summary="A Fully Immersive University Experience"
          description="Through self-guided tours, prospective students and families can navigate Brown’s campus and learn about its unique locations, programs, and culture. Whether at home or on campus, the interactive student guide accompanies users as they explore the university using a GPS-enabled campus map, student videos, and photos."
          clientImg={brownImg}
          clientLogo={brownLogo}
          clientLogoHeight={57}
          bgColor="#4E3629"
          name="brown"
        />
        <ClientBox
          summary="Automation of the Real Economy"
          description="Using research-driven insights, the Base 10 portal helps set portfolio companies up for success. Base10 is an early-stage venture capital firm that partners with entrepreneurs who are bringing automation to traditional sectors of the economy - automation of the real economy."
          clientImg={base10Img}
          clientLogo={base10Logo}
          clientLogoHeight={31}
          bgColor="#45AEEB"
          name="base10"
        />
        <YourCompanyContainer>
          <YourAppText>Your App Here</YourAppText>
          <YourAppSummary>We saved a spot for YOUR next digital product.</YourAppSummary>
          <Link to="/contact-us/">
            <ContactUsButton>Contact Us</ContactUsButton>
          </Link>
        </YourCompanyContainer>
      </ClientsGrid>
      <LetsConnectSection />
      <DarkFooter />
    </IndexLayout>
  );
};

const YourAppText = styled(NewAppTitle)`
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 1.2;
  text-align: center;
  padding: 0px;
  margin: 0px;
  margin-bottom: 9px;
  color: ${theme.MAIN_DARK_BLUE};
  @media ${desktopSize} {
    font-size: 42px;
  }
  @media ${mobileSize} {
    font-size: 28px;
  }
`;

const YourAppSummary = styled(NewAppText)`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.44;
  text-align: center;
  padding: 0px;
  margin: 0px;
  margin-bottom: 36px;
  color: ${theme.MAIN_DARK_BLUE};
  @media ${desktopSize} {
    font-size: 26px;
  }
  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const YourCompanyContainer = styled.div`
  height: 33vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 34px;
  @media ${laptopSize} {
    width: 100%;
    height: 50vw;
  }
  @media ${mobileSize} {
    height: 100vw;
    padding: 32px;
    min-height: 350px;
  }
`;

const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media ${laptopSize} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${mobileSize} {
    grid-template-columns: 1fr;
  }
`;

const ContactUsButton = styled(NewAppButton)`
  margin: 0px;
  padding: 10px 12px;
  line-height: 1;
  height: 40px;
  background-color: ${theme.MAIN_DARK_BLUE};
  color: ${theme.WHITE_COLOR};
  border-radius: 7px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  :hover {
    background-color: ${theme.ELECTRIC_BLUE};
    color: ${theme.WHITE_COLOR};
  }
`;

const PageDescriptionText = styled(NewAppText)`
  max-width: 880px;
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 60px;
  text-align: center;
  margin: 0px auto;
`;

const PageMainText = styled(NewAppTitle)`
  /* font-family: 'Objektiv Mk2'; */
  font-style: normal;
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  margin-bottom: 39px;
  /* max-width: 694px; */
  text-align: center;
  @media ${mobileSize} {
    font-size: 45px;
    line-height: 53px;
    white-space: pre-wrap;
  }
`;

const TextContainer = styled.div`
  width: fit-content;
  z-index: 2;
  @media ${laptopSize} {
    width: 100%;
  }
`;

const PageContainer = styled.div`
  position: relative;
  min-height: 500px;
  width: 100%;
  background-color: ${theme.BACKGROUND_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-top: 120px;
  @media ${laptopSize} {
    margin-top: 80px;
  }
`;
